define("discourse/plugins/inform-user/discourse/initializers/inform-user", ["exports", "discourse/lib/plugin-api", "discourse/lib/ajax"], function (_exports, _pluginApi, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.31", api => {
        api.decoratePluginOutlet('user-card-below-message-button', (elem, args) => {
          const button = elem.querySelector('.btn-instructions');
          if (button) {
            button.addEventListener('click', () => {
              button.classList.add('loading');
              (0, _ajax.ajax)('/admin/plugins/send-instructions/' + args.user.id).then(res => {
                if (res?.status === 'ok') {
                  button.classList.add('success');
                  button.disabled = true;
                }
              }).finally(() => {
                button.classList.remove('loading');
              });
            });
          }
        });
      });
    }
  };
});